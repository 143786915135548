<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { rolesService } from "../../../../services/role.service";
import {
    required,
} from "vuelidate/lib/validators";
import VsToast from '@vuesimple/vs-toast';
/**
 * Blank page component
 */
export default {
    page: {
        title: "Rôles",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader },
    data() {
        return {
            roles: [],
            items: [],
            title: "Rôles",
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "name",
            sortDesc: false,
            fields: [
                { key: "name", sortable: true },
                { key: "options"}
            ],
            form: {
                libelle: ""
            },
            submitted: false,
            showForm: false
        };
    },
    validations: {
        form: {
            libelle: { required }
        },
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.roles.length;
        }
    },
    mounted() {
        this.getRoles();
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getRoles() {
            this.roles = await rolesService.get();
        },
        async formSubmit() {
            this.submitted = true
            this.$v.$touch();
            if (!this.$v.$error) {
                const rep = await rolesService.create(this.form);
                if (rep >= 400) {
                     VsToast.show({
                        title: 'Veuillez ressayer',
                        message: 'Une erreur s\'est produite',
                        variant: 'danger',
                    })
                } else {
                    VsToast.show({
                        title: 'Enregistrement effectué',
                        message: '',
                        variant: 'success',
                    });
                    this.roles = await rolesService.get();
                    this.submitted = false; this.showForm = false;
                }
               
            }
            
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row mb-2">
            <div v-if="!showForm" class="col-md-8">
                <button @click="showForm = true" class="btn btn-success">Ajouter</button>
            </div>
        </div>
        <form v-if="showForm" class="needs-validation mb-2" @submit.prevent="formSubmit">
            <div class="row">
            <div class="col-md-12">
                <div class="mb-3">
                <label class="form-label" for="validationCustom01">Libellé</label>
                <input
                    id="validationCustom01"
                    v-model="form.libelle"
                    type="text"
                    class="form-control"
                    placeholder="Libelle"
                    value="Mark"
                    :class="{
                        'is-invalid': submitted && $v.form.libelle.$error,
                    }"
                />
                <div
                    v-if="submitted && $v.form.libelle.$error"
                    class="invalid-feedback"
                >
                    <span v-if="!$v.form.libelle.required"
                    >Champs requis.</span
                    >
                </div>
                </div>
            </div>
            </div>
            <button class="btn btn-primary" type="submit">Enregistrer</button>
            <button @click="showForm = false; form.libelle = ''; submitted = false" class="btn btn-secondary" style="margin-left: 3px;" type="submit">Annuler</button>
        </form>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show
                                        <b-form-select class="form-control form-control-sm form-select form-select-sm"
                                            v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ml-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0 datatables">
                            <b-table :items="roles" :fields="fields" responsive="sm" :per-page="perPage"
                                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"></b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows"
                                            :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>